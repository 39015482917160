import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { Header } from "./header"
import "./layout.css"
import { deviceSize } from "../styles/device"

export const MyContext = React.createContext({
  showViewer: false,
  activeConti: null,
  setActiveConti: () => {},
  setShowViewer: () => {},
})

const Layout = ({ children }) => {
  const [showViewer, setShowViewer] = useState(false)
  const [activeConti, setActiveConti] = useState(null)
  const data = useStaticQuery(graphql`
    query allContie {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <MyContext.Provider
      value={{ showViewer, setShowViewer, activeConti, setActiveConti }}
    >
      <Header siteTitle={data.site.siteMetadata.title} />
      <Wrapper>
        <main>{children}</main>
      </Wrapper>
    </MyContext.Provider>
  )
}

const Wrapper = styled.div`
  max-width: ${deviceSize.laptop};
  margin: 0px auto;
  padding: 0 16px 150px;
`

export default Layout
