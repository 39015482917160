import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Logo from "../../images/main_logo.png"
import MobileLogo from "../../images/m_main_logo.png"
import { Nav } from "../nav"
import { deviceSize, device } from "../../styles/device"

interface Props {
  siteTitle: string
}

export const Header: React.FC<Props> = ({ siteTitle }) => (
  <Wrapper>
    <Container>
      <StyledLink to="/">
        <LogoImg src={Logo} alt="dgng logo" />
      </StyledLink>
      <Nav />
    </Container>
  </Wrapper>
)

const Wrapper = styled.header``

const StyledLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  margin-left: -8px;
`
const Container = styled.div`
  margin: 0 auto;
  position: relative;
  max-width: ${deviceSize.laptop};

  padding: 16px;

  @media ${device.tablet} {
    height: 100px;
  }
`
const LogoImg = styled.div`
  background-size: cover;
  background-image: url(${MobileLogo});
  width: 120px;
  height: 25px;
  position: relative;
  top: -4px;

  @media ${device.tablet} {
    background-image: url(${Logo});
    width: 70px;
    height: 119px;
  }
`
